import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';


const Fullpage = () => (
    <ReactFullpage
        //fullpage options
        scrollingSpeed={1000} /* Options here */

        render={({ state, fullpageApi }) => {
            return (
                <ReactFullpage.Wrapper>
                    <div className="section bg-red-500">
                        <p>Section 1 (welcome to fullpage.js)</p>
                        <button onClick={() => fullpageApi.moveSectionDown()}>
                            Click me to move down
                                 </button>
                    </div>
                    <div className="section bg-blue-500">
                        <p>Section 2</p>
                    </div>
                </ReactFullpage.Wrapper>
            );
        }}
    />
);

export default Fullpage